import { FC, memo, ReactElement } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import parse, { HTMLReactParserOptions } from 'html-react-parser';
import Script from 'next/script';

import { Link } from '@common/atoms/Link';
import { EmbedImage } from '@web/atoms/EmbedImage/EmbedImage';
import { Iframe } from '@web/atoms/Iframe';
import { checkIfAllowedIframeSrc, checkIfAllowedScriptSrc } from '@web/utils/sanitizeHtml';

export interface Props {
    html: string;
    injections?: ReactElement[];
    startIndex?: number;
}

/** HTML tags that will be counted for injections (e.g. We don't want to inject ads between a title and a paragraph) */
const PARAGRAPH_TYPE_TAGS: (keyof HTMLElementTagNameMap)[] = ['p', 'script', 'table'];

export const options: HTMLReactParserOptions = {
    transform: (reactNode, domNode, index) => {
        if (!('name' in domNode) || !('attribs' in domNode)) return reactNode as JSX.Element;

        switch (domNode.name) {
            case 'viaplay-embed':
                const id = domNode.attribs['data-tag'];
                return (
                    <div key={id} id={id}>
                        <div className="ndm-load-via-play" data-tag={id}></div>
                        <Script
                            id={`${id}-script`}
                            src="https://ads.nextday.media/lib/viaplay-player/0001/cache-10/via.js"
                        />
                    </div>
                );

            case 'script':
                const isAllowedScript = checkIfAllowedScriptSrc(domNode.attribs['src']);
                if (!isAllowedScript) return renderToStaticMarkup(<>{reactNode}</>);
                // TEMP FIX FOR INSTA
                if (domNode.attribs['src'] === '//www.instagram.com/embed.js') {
                    return <script key={`script-${index}`} src={domNode.attribs['src']} async />;
                }
                return <Script key={`script-${index}`} src={domNode.attribs['src']} strategy="lazyOnload" />;

            case 'iframe':
                const isAllowedIframe = checkIfAllowedIframeSrc(domNode.attribs.src);
                if (!isAllowedIframe) return renderToStaticMarkup(<>{reactNode}</>);
                return <Iframe key={`iframe-{${index}`} src={domNode.attribs.src} {...domNode?.attribs} />;

            case 'img':
                return <EmbedImage key={`img-${index}`} src={domNode.attribs.src} {...domNode.attribs} />;

            case 'a':
                // Handle links that are not twitter links
                if (!domNode.attribs.href.match(/https:\/\/(t.co|twitter.com)/i)) {
                    return (
                        <Link key={`link-on-${index}`} href={domNode.attribs.href}>
                            {reactNode}
                        </Link>
                    );
                }
                return reactNode as JSX.Element;
            default:
                return reactNode as JSX.Element;
        }
    },
};

const Component: FC<Props> = ({ html, injections = [], startIndex = 0 }) => {
    if (!html) return null;

    const items: ReactElement[] = [];
    const parsedHtml = parse(html, options);
    const elements = Array.isArray(parsedHtml)
        ? parsedHtml
        : typeof parsedHtml === 'string'
          ? [<p key="1">{parsedHtml}</p>]
          : [parsedHtml];

    let index = startIndex;
    if (injections && injections[index]) {
        const injection = {
            ...injections[index],
            key: `injection-${index}`,
        };
        items.push(injection);
    }

    Object.values(elements).forEach((item, i) => {
        if (!item || !item?.type) return;

        const element = { ...item, key: `item-${i}` };
        items.push(element);
        if (PARAGRAPH_TYPE_TAGS.indexOf(item.type) !== -1) {
            index++;
            if (injections && injections[index]) {
                const injection = {
                    ...injections[index],
                    key: `injection-${index}`,
                };
                items.push(injection);
            }
        }
    });

    return items;
};

export const HtmlToReact = memo(Component);
