import { useCallback } from 'react';
import Script from 'next/script';

export const JobsOverviewWidget = () => {
    const loadWidget = useCallback(async () => {
        // @ts-expect-error HomeRun is attached to the window.
        window.Homerun.loadWidget('hfohtvdrc9wxjc4bo0wg');
    }, []);

    return (
        <>
            <div id="homerun-jobs-widget" data-testId="homerun-jobs-widget"></div>
            <Script
                async={true}
                src="https://d3s4clg74dg0wr.cloudfront.net/widget/js/main.js?v=7.0.0"
                onLoad={loadWidget}
            ></Script>
        </>
    );
};
