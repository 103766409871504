import { FC, useState } from 'react';
import Image, { ImageProps } from 'next/image';

import { useContextData } from '@common/useContextData';

import { StaticImage } from '../StaticImage';

/**
 * EmbedImage shares much of the logic in WebpImage but has
 * optimizations for images added by editors from external sources
 */
interface Props {
    src: string;
    width?: string;
    height?: string;
    alt?: string;
}

export const EmbedImage: FC<Props> = ({ src, alt, ...props }) => {
    const { platform } = useContextData();

    const [useFallback, setUseFallback] = useState<boolean>(false);
    const width = Number(props?.width) || 800;
    const height = Number(props?.height) || 400;

    const stylingProps: Partial<ImageProps> = {
        width,
        height,
        style: {
            maxWidth: '100%',
            height: 'auto',
        },
        priority: false,
    };

    return !useFallback ? (
        <Image
            alt={alt || ''}
            src={src}
            onError={() => {
                setUseFallback(true);
            }}
            unoptimized={true}
            {...stylingProps}
        />
    ) : (
        <StaticImage // The fallback image per platform
            alt={`fallback image ${alt}`}
            src={`/images/${platform.id}/placeholder.jpg`}
            {...stylingProps}
        />
    );
};
