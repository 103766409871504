import { FC } from 'react';
import useTranslation from 'next-translate/useTranslation';

import { Page } from '@common/clients/api';
import { ContextData } from '@common/defaults';
import { IntroBlock, IntroBlockProps } from '@web/molecules/IntroBlock';
import { NotificationBarHolder } from '@web/molecules/NotificationBar';
import { MetaHead } from '@web/organisms/MetaHead';
import { Sidebar } from '@web/organisms/Sidebar';
import { DefaultServerSideProps } from '@web/routing';

import { HtmlToReact } from './atoms';

import styles from './CustomPage.module.scss';

export interface State {
    page?: Page;
}

export interface Props extends DefaultServerSideProps {
    contextData: ContextData;
    page?: Page;
}

export const CustomPage: FC<Props> = ({ page, contextData }: Props) => {
    if (!page) throw new Error("Page can't be undefined");

    const introBlockData: IntroBlockProps = {
        superTitle: contextData.platform.name,
        title: page.title || '',
        description: '',
        additionalClasses: ['underline'],
    };

    const __translate = useTranslation('meta').t;

    return (
        <>
            <MetaHead
                title={page.title}
                description={
                    page.metaDescription ||
                    __translate('label.custom-page', {
                        pageName: page.title,
                        domainName: contextData.context.name,
                    })
                }
            />
            <div className="main-content">
                <IntroBlock {...introBlockData} />
                <div className={styles.CustomPage}>
                    <HtmlToReact html={page.text} />
                </div>
            </div>
            <Sidebar />
            <NotificationBarHolder />
        </>
    );
};
