import { type JSX, ReactNode } from 'react';
import parse, { DOMNode, Element, HTMLReactParserOptions } from 'html-react-parser';

import {
    CustomNodeTypes,
    LEXICAL_DATA_ATTR,
} from '@admin/molecules/RichEditor/Editor/plugins/CustomPluginNode';
import { XPost } from '@common/atoms/XPost';
import { options as htmlToReactOptions } from '@web/atoms/HtmlToReact/HtmlToReact';
import { Iframe } from '@web/atoms/Iframe';
import {
    JobsOverviewWidget,
    MatchBlockWrapper,
    MatchDetailSectionWrapper,
} from '@web/templates/CustomPage/atoms';

const RenderIframe = ({ data }: { data: string }) => {
    try {
        const url = new URL(data);
        if (url.protocol === 'http:' || url.protocol === 'https:') {
            return <Iframe src={url.toString()} />;
        }
    } catch {
        // extract src from iframe tag
        const match = data.match(/src="([^"]+)"/);
        if (match) {
            return <Iframe src={match[1]} />;
        }
    }
    return null;
};

const options: HTMLReactParserOptions = {
    transform: (
        reactNode: ReactNode,
        domNode: DOMNode,
        index: number,
    ): JSX.Element | string | null | void => {
        const element = domNode as Element;

        if (element?.attribs?.id === 'homerun-jobs-widget') {
            return <JobsOverviewWidget />;
        }

        const data = JSON.parse((element.attribs || {})[LEXICAL_DATA_ATTR] || '{}');

        switch (data.type) {
            case CustomNodeTypes.post:
                return <XPost data={data} />;
            case CustomNodeTypes.matchDetailSection:
                return <MatchDetailSectionWrapper matchID={data.matchID} />;
            case CustomNodeTypes.matchBlock:
                return <MatchBlockWrapper matchID={data.matchID} />;
            case CustomNodeTypes.iframe:
                return <RenderIframe data={data.iframe} />;
            case CustomNodeTypes.jobsOverview:
                return <JobsOverviewWidget />;
            default:
                return (
                    htmlToReactOptions?.transform && htmlToReactOptions.transform(reactNode, domNode, index)
                );
        }
    },
};

export const HtmlToReact = ({ html }: { html?: string }) => {
    if (!html) return null;

    return <>{parse(html, options)}</>;
};
