import { useEffect, useState } from 'react';

import { getMatch } from '@admin/molecules/RichEditor/Editor/helpers';
import { Match, ModuleScheduleSection } from '@common/clients/api';
import { useContextData } from '@common/useContextData';
import { MatchBlock } from '@web/organisms/MatchBlock';

export const MatchBlockWrapper = ({ matchID }: { matchID: string }) => {
    const [match, setMatch] = useState<Match | null>(null);
    const contextData = useContextData();

    useEffect(() => {
        getMatch({ matchID, contextData }).then(setMatch);
    }, [matchID, contextData]);

    if (!match) return null;

    return <MatchBlock match={match} trackerName={ModuleScheduleSection.ODDS_DOSSIER_HOME} />;
};
