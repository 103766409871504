
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { fetchPage } from '@web/handlers/fetchPage';
import { getNextWebServerSidePropsWrapper, GetProps, GetPropsReturn, NextWebServerSideProps, Route, } from '@web/routing';
import { CustomPage, Props } from '@web/templates/CustomPage';
const prepareProps: GetProps<Props> = async (contextData, serverContext): GetPropsReturn<Props> => {
    const props: Props = {
        contextData
    };
    const slug = String(serverContext.query.slug) || '';
    if (slug !== 'rss-feeds') {
        const page = await fetchPage(contextData, slug, false);
        if (!page) {
            return {
                notFound: true
            };
        }
        if (page.adsEnabled === false) {
            contextData.adsEnabled = false;
        }
        props.page = page;
    }
    return props;
};
const getServerSideProps: NextWebServerSideProps<Props> = getNextWebServerSidePropsWrapper(prepareProps, Route.Page);
export const Home = CustomPage;
export default Home;

    async function __Next_Translate__getServerSideProps__193b9fe7a74__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/page/[slug]',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__193b9fe7a74__ as getServerSideProps }
  