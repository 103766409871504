import { FC, useCallback, useEffect, useRef, useState } from 'react';

import styles from './Iframe.module.scss';

const IFRAME_CUSTOM_STYLE = [
    '24liveblog',
    'apple',
    'art19',
    'dailymotion',
    'google',
    'kijk',
    'roninmedia',
    'service',
    'spotify',
    'youtube',
];

export interface Props {
    src: string;
    allowFullScreen?: boolean;
    frameBorder?: number | string;
    height?: string;
}

export const Iframe: FC<Props> = ({ src, height, frameBorder = 0, allowFullScreen }) => {
    const ref = useRef<HTMLIFrameElement>(null);
    const [loading, setLoading] = useState(true);
    const srcMatch = IFRAME_CUSTOM_STYLE.find((srcDomain) => src?.includes(srcDomain)) || 'default';
    const isAutoScale = /roninmedia/.test(src);

    const iframeClass = `${styles[`iframe-${srcMatch}`]}`;
    const wrapperClass = loading ? styles.loading : styles.loaded;

    const adjustIframeHeight = useCallback((e: WindowEventMap['message']) => {
        if (!ref.current || !e.data.height) return;
        ref.current.height = e.data.height + 'px';
    }, []);

    useEffect(() => {
        if (isAutoScale) {
            window.addEventListener('message', adjustIframeHeight);
        }
        return () => window.removeEventListener('message', adjustIframeHeight);
    }, [isAutoScale, adjustIframeHeight]);

    return (
        <div className={`${styles.Iframe} ${wrapperClass}`}>
            <iframe
                ref={ref}
                src={src}
                className={iframeClass}
                width={'100%'} // FIXME: We need a better solution than setting width and height to 100% as iframe html have different default values
                height={height || '100%'}
                style={{ border: frameBorder }}
                onLoad={() => setLoading(false)}
                loading="lazy"
                allowFullScreen={allowFullScreen}
            />
        </div>
    );
};
