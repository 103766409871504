import { FC } from 'react';
import Image, { ImageProps } from 'next/image';

import { Link } from '@common/atoms/Link/Link';

export interface Props extends ImageProps {
    src: string;
    url?: string;
    className?: string;
}

export const BookmakerLogo: FC<Props> = (props) => {
    const { url, src, className } = props;
    return (
        <Link className={className} href={url || ''} isSponsored>
            <Image src={src} width={0} height={0} unoptimized={true} alt="" loader={({ src }) => src} />
        </Link>
    );
};
